<template>
    <div class="d-flex w-100 h-100 align-content-center px-3 justify-content-center">
        <img style="width:100%; height:310px" class="w-20 mx-auto h-10 flex-grow-1" src="../../../assets/images/new_loader_2.svg" />
    </div>
</template>
<script>
    export default {
        name : 'CreateVideoLoader'
    }
</script>
